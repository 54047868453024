// src/i18n.js
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

// Translation resources
const resources = {
  en: {
    translation: {
      companyDeleted: "Company successfully deleted!",
      usersAndTicketsDeleted:
        "Deleted {{deletedUsers}} users and {{deletedTickets}} tickets.",
      confirmDeleteCompany:
        "Are you sure you want to delete {{companyName}}? This will delete {{userCount}} user(s) and {{ticketCount}} ticket(s) associated with this company.",
    },
  },
  fr: {
    translation: {
      "Welcome to React": "Bienvenue à React",
      Dashboard: "Tableau de bord",
      "Create Ticket": "Créer un ticket",
      "Create a New Ticket": "Créer un nouveau ticket",
      All: "Tous",
      Open: "Ouverts",
      open: "ouvert",
      "In Progress": "En cours",
      "In progress": "En cours",
      "in progress": "en cours",
      Pending: "En attente",
      pending: "en attente",
      Resolved: "Résolus",
      resolved: "résolu",
      Closed: "Fermés",
      closed: "fermé",
      Type: "Type",
      Priority: "Priorité",
      "Service Request": "Demande de service",
      "service request": "demande de service",
      "change request": "demande de changement",
      "Change Request": "Demande de changement",
      Incident: "Incident",
      incident: "incident",
      Low: "Faible",
      low: "faible",
      Medium: "Moyenne",
      medium: "moyenne",
      High: "Élevée",
      high: "élevée",
      "Hide Closed & Resolved": "Masquer les tickets fermés & résolus",
      "Show All Tickets": "Afficher tous les tickets",
      Title: "Titre",
      title: "titre",
      Description: "Description",
      description: "description",
      Submit: "Soumettre",
      Cancel: "Annuler",
      Close: "Fermer",
      "Edit Ticket": "Modifier le ticket",
      "Delete Ticket": "Supprimer le ticket",
      "Are you sure you want to delete this ticket?":
        "Voulez-vous vraiment supprimer ce ticket?",
      Yes: "Oui",
      No: "Non",
      "Ticket Details": "Détails du ticket",
      "Created At": "Créé à",
      "Updated At": "Mis à jour à",
      Status: "Statut",
      Reference: "Référence",
      Actions: "Actions",
      Edit: "Modifier",
      Delete: "Supprimer",
      View: "Vue",
      "Export to CSV": "Exporter en CSV",
      Unassigned: "Non assigné",
      unassigned: "non assigné",
      "Search filter": "Filtre de recherche",
      Creator: "Créateur",
      "Assigned To": "Assigné à",
      Profile: "Profil",
      User: "Utilisateur",
      Year: "Année",
      Week: "Semaine",
      "Select week": "Choisir une semaine",
      "Select user": "Choisir un utilisateur",
      "Select company": "Choisir une entreprise",
      "Users Management": "Gestion des utilisateurs",
      "Companies Management": "Gestion des entreprises",
      "SMTP Settings": "Configuration SMTP",
      "SLA Settings": "Configuration des SLAs",
      Logout: "Se déconnecter",
      Login: "S'identifier",
      "Delete Selected": "Supprimer la sélection",
      "Delete Ticket(s)": "Supprimer le(s) ticket(s)",
      "Are you sure you want to delete the selected ticket(s)?":
        "Voulez-vous vraiment supprimer le(s) ticket(s) sélectionné(s)?",
      "Tickets per page": "Tickets par page",
      of: "de",
      "Last Change": "Mis à jour",
      Assignee: "Assigné",
      "The page you are looking for does not exist.":
        "La page que vous recherchez n'existe pas.",
      "Go Back Home": "Retourner à l'accueil",
      "Loading...": "Chargement...",
      "Password does not meet the requirements":
        "Le mot de passe ne répond pas aux exigences",
      "Passwords do not match": "Les mots de passe ne correspondent pas",
      "Password must be at least 8 characters long":
        "Le mot de passe doit comporter au moins 8 caractères",
      "Set Password": "Enregistrer le mot de passe",
      Password: "Mot de passe",
      "New Password": "Nouveau mot de passe",
      "Confirm New Password": "Confirmer le nouveau mot de passe",
      "Password must have at least one special character":
        "Le mot de passe doit comporter au moins un caractère spécial",
      "Password must have at least one number":
        "Le mot de passe doit comporter au moins un chiffre",
      "Password must have at least one capital letter":
        "Le mot de passe doit comporter au moins une lettre majuscule",
      "Passwords must match": "Les mots de passe doivent correspondre",
      "Access Denied": "Accès refusé",
      "You do not have permission to view this page.":
        "Vous n'avez pas la permission de voir cette page.",
      "Attachment deleted successfully": "Pièce jointe supprimée avec succès",
      "Error deleting attachment:":
        "Erreur lors de la suppression de la pièce jointe:",
      "Attachment added successfully": "Pièce jointe ajoutée avec succès",
      "Error uploading file:": "Erreur lors de l'ajout de la pièce jointe:",
      "Ticket updated successfully": "Ticket mis à jour avec succès",
      "Error updating ticket": "Erreur lors de la mise à jour du ticket",
      "Ticket deleted successfully": "Ticket supprimé avec succès",
      "Failed to delete ticket": "Échec de la suppression du ticket",
      "View and Edit": "Voir et modifier",
      Comments: "Commentaires",
      Attachments: "Pièces jointes",
      History: "Historique",
      "Resolved At:": "Résolu le :",
      "Closed At:": "Fermé le :",
      "Add a Comment": "Ajouter un commentaire",
      "Save Changes": "Enregistrer les modifications",
      "Comment by": "Commentaire par",
      "No comments so far": "Pas de commentaires pour le moment",
      by: "par",
      on: "le",
      "No attachments so far": "Pas de pièces jointes pour le moment",
      "Delete Attachment": "Supprimer la pièce jointe",
      "Are you sure you want to delete this attachment?":
        "Voulez-vous vraiment supprimer cette pièce jointe?",
      "Ticket created": "Ticket créé",
      "Ticket updated": "Ticket mis à jour",
      "Ticket deleted": "Ticket supprimé",
      "Comment added": "Commentaire ajouté",
      "Attachment added": "Pièce jointe ajoutée",
      "Attachment deleted": "Pièce jointe supprimée",
      "Status changed to in progress": "Statut changé vers en cours",
      "Status changed to pending": "Statut changé vers en attente",
      "Status changed to resolved": "Statut changé vers résolu",
      "Status changed to closed": "Statut changé vers fermé",
      "Status changed to open": "Statut changé vers ouvert",
      "Ticket updated by": "Ticket mis à jour par",
      "Assignee changed": "Assigné modifié",
      from: "de",
      to: "à",
      "New comment posted": "Nouveau commentaire posté",
      "Company successfully deleted!": "Entreprise supprimée avec succès !",
      "Error deleting company:":
        "Erreur lors de la suppression de l'entreprise :",
      "Company deletion error! Have you deleted its user(s) first?":
        "Erreur de suppression de l'entreprise ! Avez-vous d'abord supprimé son(ses) utilisateur(s) ?",
      "Company successfully edited!": "Entreprise modifiée avec succès !",
      "Company successfully created!": "Entreprise créée avec succès !",
      "Company edit failure!": "Échec de la modification de l'entreprise !",
      "Companies List": "Liste des entreprises",
      "Create Company": "Créer une entreprise",
      Name: "Nom",
      "Ticket Prefix": "Préfixe de ticket",
      "Primary Contact": "Contact principal",
      "Primary Contact Phone": "Téléphone de contact",
      "Primary Contact Email": "Mail de contact",
      "Contact Phone": "Téléphone de contact",
      "Contact Email": "Email de contact",
      "Loading map...": "Chargement de la carte...",
      "Create New Company": "Créer une nouvelle entreprise",
      "Company Name": "Nom de l'entreprise",
      Address: "Adresse",
      Website: "Site web",
      "Logo URL": "URL du logo",
      "Confirm Delete": "Confirmer la suppression",
      "Are you sure you want to delete": "Voulez-vous vraiment supprimer",
      "Delete Company": "Supprimer",
      "Failed to load SLA settings.": "Échec du chargement des paramètres SLA.",
      "SLA settings updated successfully.":
        "Paramètres SLA mis à jour avec succès.",
      "Failed to update SLA settings.":
        "Échec de la mise à jour des paramètres SLA.",
      "Low priority tickets": "Tickets de faible priorité",
      "Medium priority tickets": "Tickets de priorité moyenne",
      "High priority tickets": "Tickets de haute priorité",
      "Save Settings": "Enregistrer les paramètres",
      "User successfully deleted!": "Utilisateur supprimé avec succès !",
      "User deletion failure!": "Échec de la suppression de l'utilisateur !",
      "Username must be 10 characters or less and is required.":
        "Le nom d'utilisateur doit comporter 10 caractères ou moins et est requis.",
      "Email must be 255 characters or less and is required.":
        "L'email doit comporter 255 caractères ou moins et est requis.",
      "Firstname must be 25 characters or less and is required.":
        "Le prénom doit comporter 25 caractères ou moins et est requis.",
      "Lastname must be 25 characters or less and is required.":
        "Le nom de famille doit comporter 25 caractères ou moins et est requis.",
      "User successfully edited!": "Utilisateur modifié avec succès !",
      "User edit failed!": "Échec de la modification de l'utilisateur !",
      "User successfully created!": "Utilisateur créé avec succès !",
      "User creation failure!": "Échec de la création de l'utilisateur !",
      "Error fetching companies:":
        "Erreur lors de la récupération des entreprises :",
      Never: "Jamais",
      Username: "Utilisateur",
      Email: "Email",
      "First Name": "Prénom",
      Firstname: "Prénom",
      "Last Name": "Nom",
      Lastname: "Nom",
      Role: "Rôle",
      Company: "Entreprise",
      Phone: "Téléphone",
      Enabled: "Activé",
      "Last Login": "Dernière connexion",
      true: "oui",
      True: "Oui",
      false: "non",
      False: "Non",
      user: "utilisateur",
      "Create User": "Créer un utilisateur",
      "User List": "Liste des utilisateurs",
      "Failed to load SMTP settings.":
        "Échec du chargement des paramètres SMTP.",
      "SMTP settings updated successfully.":
        "Paramètres SMTP mis à jour avec succès.",
      "Failed to update SMTP settings.":
        "Échec de la mise à jour des paramètres SMTP.",
      "Server @": "Serveur @",
      "SMTP User": "Utilisateur SMTP",
      "SMTP Port": "Port SMTP",
      "SMTP Password": "Mot de passe SMTP",
      "From @": "De @",
      "From Email Address": "Adresse e-mail de l'expéditeur",
      "Test Email": "Email de test",
      "Save Setttings": "Enregistrer les paramètres",
      Secure: "Sécurisé",
      "To @": "À @",
      "Test Email Address": "Adresse e-mail de test",
      "Send Test Email": "Envoyer un e-mail de test",
      "Sending test email...": "Envoi d'un e-mail de test...",
      "Failed to login. Please check your credentials.":
        "Échec de la connexion. Veuillez vérifier vos identifiants.",
      "Failed to verify 2FA. Please enter a valid token.":
        "Échec de la vérification 2FA. Veuillez saisir un jeton valide.",
      "Please enter your login and password":
        "Veuillez entrer votre identifiant et votre mot de passe",
      "Please fill all required fields.":
        "Veuillez remplir tous les champs obligatoires.",
      "2FA Token": "Jeton 2FA",
      "Verify 2FA": "Vérifier 2FA",
      "New passwords do not match!": "Les mots de passe ne correspondent pas !",
      "Password successfully changed!": "Mot de passe modifié avec succès !",
      "Error changing password": "Erreur lors du changement de mot de passe",
      "Updated profile": "Profil mis à jour",
      "Error updating user data":
        "Erreur lors de la mise à jour des données utilisateur",
      "Error updating avatar:": "Erreur lors de la mise à jour de l'avatar :",
      "2FA disabled successfully": "2FA désactivé avec succès",
      "Error disabling 2FA": "Erreur lors de la désactivation du 2FA",
      "2FA enabled successfully": "2FA activé avec succès",
      "Error enabling 2FA": "Erreur lors de l'activation du 2FA",
      "No Company": "Pas d'entreprise",
      "User Profile": "Profil d'utilisateur",
      Update: "Mettre à jour",
      "Change Password": "Changer le mot de passe",
      "🔓Disable 2FA": "🔓Désactiver 2FA",
      "🔒Enable 2FA": "🔒Activer 2FA",
      "Current Password:": "Mot de passe actuel :",
      "New Password:": "Nouveau mot de passe :",
      "Confirm New Password:": "Confirmer le nouveau mot de passe :",
      "Scan the QR Code or enter this code:":
        "Scannez le code QR ou entrez ce code :",
      "Enter your token:": "Entrez votre jeton :",
      "Disable Two-Factor Authentication":
        "Désactiver l'authentification à deux facteurs",
      "Enable Two-Factor Authentication":
        "Activer l'authentification à deux facteurs",
      "Verify and Enable": "Vérifier et activer",
      "Are you sure you want to disable Two-Factor Authentication?":
        "Voulez-vous vraiment désactiver l'authentification à deux facteurs ?",
      "Admin - Create New User": "Admin - Créer un nouvel utilisateur",
      "Delete User": "Supprimer l'utilisateur",
      "Time Spent": "Temps passé",
      "Time spent in minutes": "Temps passé en minutes",
      "Log Time Spent": "Comptabiliser",
      spent: "a passé",
      "minutes on this ticket.": "minutes sur ce ticket.",
      "Logged at:": "Enregistré le:",
      " (Overdue)": " (Retard)",
      Next: "Suivant",
      Previous: "Précédent",
      "Create Company and User": "Créer l'entreprise et l'utilisateur",
      "Expected Due Date": "Date de résolution cible",
      "Created by": "Créé par",
      companyDeleted: "Entreprise supprimée avec succès !",
      usersAndTicketsDeleted:
        "{{deletedUsers}} utilisateurs et {{deletedTickets}} tickets supprimés.",
      confirmDeleteCompany:
        "Voulez-vous vraiment supprimer {{companyName}} ? Cela supprimera {{userCount}} utilisateur(s) et {{ticketCount}} ticket(s) associés à cette entreprise.",
      "Deleted User": "Supprimé",
      day: "jour",
      days: "jours",
      "Duty Management": "Gestion des gardes",
      "Manage Duty Schedules": "Liste des gardes",
      "Create Duty": "Créer une garde",
      "Logged Hours": "Heures prestées",
      "Log Hours": "Enregistrer des heures",
      Save: "Enregistrer",
      "Duty Details": "Détails de la garde",
      "Delete Duty": "Supprimer la garde",
      "Are you sure you want to delete this duty schedule?":
        "Voulez-vous vraiment supprimer cette garde ?",
      "Duty Schedules": "Liste des gardes",
      Month: "Mois",
      Start: "Début",
      End: "Fin",
      "User on Duty": "Utilisateur de garde",
      "Logged Hours Report": "Rapport des heures prestées",
      Hours: "Heures",
      hours: "heures",
      "Duty Schedules Details": "Détails des gardes",
      "Log Entries": "Enregistrements",
      "Log Time to Duty Schedule": "Enregistrer des heures de garde",
      "Do you also want to log the time spent to your duty schedule?":
        "Voulez-vous également enregistrer le temps passé dans votre garde ?",
      "Readeable only by other admins":
        "Lisible uniquement par les autres administrateurs",
      "admins only": "admins uniquement",
      "Priority changed": "Priorité modifiée",
      "Comment deleted": "Commentaire supprimé",
      "Set New Password": "Définir un nouveau mot de passe",
      "Total Logged Time": "Temps total enregistré",
      "Tickets with Logged Time During This Duty":
        "Tickets avec temps enregistré",
      "Logged time": "Temps enregistré",
      "Delete Time Spent": "Supprimer le temps enregistré",
      "Are you sure you want to delete this entry?":
        "Voulez-vous vraiment supprimer cet enregistrement ?",
      "Monthly Report": "Rapport mensuel",
      "Select Company": "Choisir une entreprise",
      "Select Month": "Choisir un mois",
      "Select Year": "Choisir une année",
      "No tickets available for this month.": "Pas de tickets pour ce mois.",
      "Total for": "Total pour",
      "Tickets with logged time": "Tickets avec temps enregistré",
      "No logged time during this period":
        "Pas de temps enregistré pour cette période",
      January: "Janvier",
      February: "Février",
      March: "Mars",
      April: "Avril",
      May: "Mai",
      June: "Juin",
      July: "Juillet",
      August: "Août",
      September: "Septembre",
      October: "Octobre",
      November: "Novembre",
      December: "Décembre",
      "Delete Comment": "Supprimer le commentaire",
      "Are you sure you want to delete this comment?":
        "Voulez-vous vraiment supprimer ce commentaire ?",
      "Company is required.": "L'entreprise est requise.",
      "Type is required.": "Le type est requis.",
      "Priority is required.": "La priorité est requise.",
      "Title is required.": "Le titre est requis.",
      "Description is required.": "La description est requise.",
    },
  },
};

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources,
    fallbackLng: "en",
    detection: {
      order: ["localStorage", "navigator"],
      caches: ["localStorage"],
      lookupLocalStorage: "i18nextLng",
      lookupFromPathIndex: 0,
      lookupFromSubdomainIndex: 0,
    },

    keySeparator: false,

    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
